export default ({ app /*  router, store  Vue */ }) => {
    global.api_mock = {
        getCampaigns(id) {
            const campaigns = [
                {
                    id: 1,
                    name: "Campaign 1",
                    field_thmb: "campaign-1.png",
                    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
                    field_categories: "",
                    field_amount_raised: "45000",
                    field_progress: 0.1,
                    field_profile_name: "",
                    field_profile_thumb: "",
                    field_story: "",
                    field_perks: {},
                    field_risk: "",
                    field_faqs: {},
                    field_updates: {},
                    field_creators: ""
                },
                {
                    id: 2,
                    name: "Campaign 2",
                    field_thmb: "campaign-2.png",
                    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
                    field_categories: "",
                    field_amount_raised: "25000",
                    field_progress: 0.4,
                    field_profile_name: "",
                    field_profile_thumb: "",
                    field_story: "",
                    field_perks: {},
                    field_risk: "",
                    field_faqs: {},
                    field_updates: {},
                    field_creators: ""
                },
                {
                    id: 3,
                    name: "Campaign 3",
                    field_thmb: "campaign-3.png",
                    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
                    field_categories: "",
                    field_amount_raised: "65000",
                    field_progress: 0.7,
                    field_profile_name: "",
                    field_profile_thumb: "",
                    field_story: "",
                    field_perks: {},
                    field_risk: "",
                    field_faqs: {},
                    field_updates: {},
                    field_creators: ""
                }
            ];
            if (id) {
                const campaign = campaigns.filter(campaign => campaign.id == id);
            
                return campaign[0];
            } else {
                return campaigns;
            }
        },
        getCampaignCategories() {
            const campaignCategories = ["Math", "Science", "History", "Writing Skills", "Vocabulary", "Grammar"]
            return campaignCategories;
        }
    };
};