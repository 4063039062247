import axios from "axios";
import { baseUrl, rtBaseUrl, getToken } from "../store/constants";
import { LocalStorage, Notify } from "quasar";
import { Axios, jsonAxios } from "boot/axios";
// import Vue from "vue";

export default ({ app, router, store /* Vue */ }) => {
  global.api = {
    async getConnectUrls() {
      let networkUrls = {};
      // console.log(getToken());
      try {
        const networkUrlsResponse = await axios
          .get(baseUrl + "/api/v1/auth/all?_format=json", {
            headers: { Authorization: `Bearer ${getToken()}` }
          })
          .then(response => {
            // console.log(response);
            networkUrls = response;
          });
        // console.log(networkUrlsResponse);
      } catch (error) {
        // console.log(error);
      }
      return networkUrls;
    },

    async getConnectNetworks() {
      let networks = {};
      // console.log(getToken());
      try {
        const networksResponse = await axios
          .get(baseUrl + "/api/v1/networks/?_format=json", {
            headers: { Authorization: `Bearer ${getToken()}` }
          })
          .then(response => {
            // console.log("Networks", response);
            networks = response;
          });
        // console.log(networkUrlsResponse);
      } catch (error) {
        // console.log(error);
      }
      return networks;
    },
    async getNetworkOrders() {
      let fetchOrders = {};
      // console.log(getToken());
      try {
        const ordersResponse = await axios
          .get(baseUrl + "/api/v1/fetch_orders?_format=json", {
            headers: { Authorization: `Bearer ${getToken()}` }
          })
          .then(response => {
            // console.log(response);
            fetchOrders = response;
          });
        // console.log(ordersResponse);
      } catch (error) {
        // console.log(error);
      }
      return fetchOrders;
    },
    //range is an Object of {min: 'today', max: 'last month'}
    /* Useful ranges
    last day of this month
    first day of this month
    last day of previous month
    first day of previous month
    today
    + 15 days
    - 15 days
    */
    async getOrders(id = "all", page = 0, range) {
      let orders = {};
      let url = "orders/" + id + "?";
      // console.log("Range", range);
      if (range) {
        url =
          "orders-range/all?field_date_ship_by_range[min]=" +
          range.min +
          "&field_date_ship_by_range[max]=" +
          range.max +
          "&";
      }
      // console.log("URL", url);

      try {
        const ordersResponse = await axios
          .get(baseUrl + "/api/v1/" + url + "page=" + page + "&_format=json", {
            headers: { Authorization: `Bearer ${getToken()}` }
          })
          .then(response => {
            // console.log(response);
            orders = response;
          });
        // console.log(ordersResponse);
      } catch (error) {
        // console.log(error);
      }
      return orders;
    },

    async getUser(uid) {
      // Set up funtions to use later
      var avatar = baseUrl + "/themes/custom/gin/media/avatar.png";
      function assingUserData(userProfile, userPicture) {
        userProfile.user_picture = { url: avatar };
        if (userPicture) {
          userProfile.user_picture = { url: userPicture };
        }
        //  console.log('Inside assignUserData', userProfile)
        LocalStorage.set("user_profile", userProfile);
        LocalStorage.set("uuid", userProfile.uuid);
        store.commit("updateUser", userProfile);
        return userProfile;
      }
      // console.log("UID", uid);
      const token = getToken();
      var user = [];
      await Axios.get(rtBaseUrl + "/user/" + uid, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(response => {
          console.log("RT response", response);
          console.log("RT response userpic", response.data.user_picture);
          if (response.data.user_picture !== "") {
            // Get Avatar
            user = assingUserData(
              response.data,
              response.data.user_picture.url
            );
          } else {
            console.log("No avatar in drupal");
            user = assingUserData(response.data);
          }
        })
        .catch(error => {
          Notify.create({
            color: "negative",
            position: "top",
            message: error
          });
        });
      return user;
    },
    // Get multiple or single user details
    getUserDetails(detail = "") {
      var user = LocalStorage.getItem("user_profile");
      if (detail) {
        return user[detail];
      } else {
        return user;
      }
    },
    async postProcessStep(step_name) {
      let processStep = "";
      // console.log(getToken());
      try {
        const processStepResponse = await axios
          .post(
            baseUrl + "/api/v1/process_step?_format=json",
            { name: step_name },
            {
              headers: { Authorization: `Bearer ${getToken()}` }
            }
          )
          .then(response => {
            // console.log(response);
            processStep = response;
          });
        // console.log(ordersResponse);
      } catch (error) {
        // console.log(error);
      }
      return processStep;
    },
    async patchProcessStep(step, new_name) {
      let processStep = "";
      console.log("Step", step);
      console.log("new_name", new_name);
      try {
        await axios
          .patch(
            baseUrl + "/api/v1/process_step?_format=json",
            { step_id: step.tid, new_name: new_name },
            {
              headers: { Authorization: `Bearer ${getToken()}` }
            }
          )
          .then(response => {
            console.log("Patch response", response);
            processStep = response;
          });
        // console.log(ordersResponse);
      } catch (error) {
        console.log(error);
      }
      return processStep;
    }
  };
};
