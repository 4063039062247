import { date } from "quasar";
export default ({ app /*  router, store  Vue */ }) => {
  global.aquila = {
    imgSrcset(url, extention) {
      const sizes = [
        ["1x", "280w"],
        ["2x", "440w"],
        ["3x", "800w"],
        ["4x", "1024w"]
      ];
      let imgSrcset = "";
      sizes.forEach((item, index) => {
        // console.log('Item', item)
        // console.log('index', index)
        imgSrcset += url + "-" + item[0] + extention + " " + item[1];
        if (sizes.length !== index + 1) {
          imgSrcset += ", ";
        }
      });
      // console.log('imgSrcSet', imgSrcset)
      return imgSrcset;
    },
    formatDate(date_to_format, format) {
      return date.formatDate(date_to_format, format);
    },
    fieldExists(val) {
      return !!val || "Password is required";
    },

    hasMinimumCharacters(val) {
      return val.length > 7 || "Password should be at least 8 characters";
    },

    hasLowerCase(val) {
      return (
        /[a-z]/.test(val) || "Password must have at least one lowercase letter"
      );
    },

    hasUpperCase(val) {
      return (
        /[A-Z]/.test(val) || "Password must have at least one uppercase letter"
      );
    },

    hasNumber(val) {
      return /[0-9]/.test(val) || "Password must contain a number";
    },

    hasSpecialCharacter(val) {
      return (
        /[!@#$%^&*_]/.test(val) || "Password must have a special character"
      );
    },
    isInTheFuture(val) {
      const diff = date.getDateDiff(
        new Date(val),
        new Date().getTime(),
        "days"
      );
      //  console.log('Diff', diff)
      if (diff <= 0) {
        return false || "This should be in the future";
      } else {
        return true;
      }
    }
  };
};
