import Vue from "vue";
import axios from "axios";
import { baseUrl } from "../store/constants";

// declare module 'vue/types/vue' {
//   interface Vue {
//     $axios: AxiosInstance;
//   }
// }

const apiBase = `https://${baseUrl}`;

const Axios = axios.create({
  // The hostname of the backend api for the current window location
  baseURL: apiBase
});
const jsonAxios = axios.create({
  headers: {
    Accept: "application/vnd.api+json",
    "Content-type": "application/vnd.api+json"
  }
});

Vue.prototype.$Axios = Axios;
Vue.prototype.$jsonAxios = jsonAxios;

export { Axios, jsonAxios };
