import Axios from "axios";
import {
  baseUrl,
  rtBaseUrl,
  userPayload,
  prettyErrors,
  getUuid,
  getToken
} from "../store/constants";
import { LocalStorage, Notify } from "quasar";
import { jsonAxios } from "boot/axios";
import Vue from "vue";

export default ({ app, router, store /* Vue */ }) => {
  global.auth = {
    async login(email, password) {
      // Set up funtions to use later
      var avatar = baseUrl + "/themes/custom/gin/media/avatar.png";
      function assingUserData(userProfile, userPicture) {
        userProfile.user_picture = { url: avatar };
        if (userPicture) {
          userProfile.user_picture = { url: userPicture };
        }
        // console.log("Inside assignUserData", userProfile);
        LocalStorage.set("user_profile", userProfile);
        LocalStorage.set("uuid", userProfile.uuid);
        store.commit("updateUser", userProfile);
      }

      // Login Function
      Axios.post(baseUrl + "/user/login?_format=json", {
        name: email,
        pass: password
      })
        .then(function(response) {
          // console.log(response)
          LocalStorage.set("access_token", response.data.access_token);
          Vue.set(global.auth, "access_token", response.data.access_token);
          LocalStorage.set("csrf_token", response.data.csrf_token);
          LocalStorage.set("logout_token", response.data.logout_token);
          LocalStorage.set("user_profile", response.data.current_user);
          userPayload.token = response.data.access_token;
          // window.location = 'user'
        })
        .catch(function(error) {
          // console.log("Error", error);
          Notify.create({
            color: "negative",
            position: "top",
            message: error
          });
          throw error;
        })
        .then(function() {
          // console.log("Finally");
          // console.log("Response", LocalStorage.getItem("user_profile"));
          if (
            LocalStorage.getItem("access_token") !== "" &&
            LocalStorage.getItem("access_token") !== null
          ) {
            const user = LocalStorage.getItem("user_profile");
            Axios.get(rtBaseUrl + "/user/" + user.uid, {
              headers: { Authorization: `Bearer ${userPayload.token}` }
            })
              .then(response => {
                // console.log("Picture");
                // console.log(response.data);
                if (response.data.user_picture !== false) {
                  // Get Avatar
                  assingUserData(response.data, response.data.user_picture.url);
                } else {
                  // console.log("No avatar in drupal");
                  assingUserData(response.data);
                }
                return response;
              })
              .catch(error => {
                Notify.create({
                  color: "negative",
                  position: "top",
                  message: error
                });
                throw error;
              })
              .then(() => {
                router.push({ path: "/user/dashboard" });
              });
          } else {
            Notify.create({
              color: "negative",
              position: "top",
              message:
                "Something went wrong, please check your username/password"
            });
          }
        });
      const user = LocalStorage.getItem("user_profile");
      if (user) {
        global.auth.user_profile = user || null;
        global.auth.access_token = LocalStorage.getItem("access_token") || null;
        store.commit("updateUser", user);
      }
    },
    loadUser() {
      let user = "";
      if (store.state.user.length > 0) {
        // console.log("user from state", store.state.user);
        user = store.state.user;
      } else {
        user = LocalStorage.getItem("user_profile");
        store.commit("updateUser", user);
        // console.log("user from storage", user);
      }
      return user;
    },
    async updateUser(userFields) {
      // console.log("FIELDS", userFields);
      const token = getToken();
      const uid = global.api.getUserDetails("uid");
      let success = false;

      try {
        await Axios.patch(rtBaseUrl + "/user/" + uid, userFields, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(response => {
          // console.log("Response", response);
          success = true;
          Notify.create({
            color: "positive",
            position: "top",
            message: "Your info has been updated!"
          });
        });
      } catch (error) {
        // console.log("Error", error);
        Notify.create({
          color: "negative",
          position: "top",
          message: error
        });
      }
      // console.log(success);
      return userFields;
    },
    store_user_profile(response) {
      LocalStorage.set("user_profile", response);
      Vue.set(store.auth, "user_profile", response);
    },
    // firstName:string, lastName:string, email:string, password:string, redirect:boolean
    async createAccount(firstName, lastName, email, password, redirect) {
      let success = false;
      try {
        await Axios.post(baseUrl + "/user/register?_format=json", {
          name: { value: email },
          pass: { value: password },
          mail: { value: email },
          field_first_name: { value: firstName },
          field_last_name: { value: lastName }
        });
        success = true;
      } catch (error) {
        // console.log(error);
        Notify.create({
          color: "negative",
          position: "top",
          message: prettyErrors(error.response.data.message)
          // message: prettyErrors(error.response.data.message)
        });
      }

      if (success) {
        Axios.post(baseUrl + "/user/login?_format=json", {
          name: email,
          pass: password
        })
          .then(response => {
            // console.log(response)
            LocalStorage.set("access_token", response.data.access_token);
            Vue.set(global.auth, "access_token", response.data.access_token);
            LocalStorage.set("csrf_token", response.data.csrf_token);
            LocalStorage.set("logout_token", response.data.logout_token);
            LocalStorage.set("user_profile", response.data.current_user);
            userPayload.token = response.data.access_token;

            jsonAxios
              .get(
                baseUrl +
                  "/jsonapi/user/user?filter[uid][value]=" +
                  response.data.current_user.uid,
                { headers: { Authorization: `Bearer ${userPayload.token}` } }
              )
              .then(response => {
                LocalStorage.set(
                  "user_profile",
                  response.data.data[0].attributes
                );
                LocalStorage.set("uuid", response.data.data[0].id);
                store.commit("updateUser", response.data.data[0].attributes);
                if (redirect) {
                  router.push({ path: "/shop" });
                }
              });
          })
          .catch(function(error) {
            // console.log(error);
            Notify.create({
              color: "negative",
              position: "top",
              message: error
            });
          });
      }
      const user = LocalStorage.getItem("user_profile");
      if (user) {
        global.auth.user_profile = user || null;
        global.auth.access_token = LocalStorage.getItem("access_token") || null;
      }
      // returns true if account is created
      return success;
    },
    async reset(account = "") {
      try {
        return await axios.post(baseUrl + "/api/user/ulipass", { account });
      } catch (e) {
        Notify.create({
          color: "negative",
          position: "top",
          message: e
        });
      }
    },
    async ulilogin(uid = "", timestamp = "", hash = "") {
      try {
        return await axios.post(baseUrl + "/api/user/uli/login", {
          uid,
          timestamp,
          hash
        });
      } catch (e) {
        // console.log('Error', e.response.data.message)
        Notify.create({
          color: "negative",
          position: "top",
          message: e.response.data.message
        });
        this.$router.push("/reset");
      }
    }
  };

  router.beforeEach((to, from, next) => {
    // Check to see if the route has the meta field "authRequired" set to true
    const authRequired = to.matched.some(route => route.meta.authRequired);
    const isSignedIn = LocalStorage.getItem("access_token") || null;
    if (authRequired && !isSignedIn) {
      LocalStorage.set("initial_path", to.path);

      return next("/");
    }

    next();
  });
};

const logout = () => {
  // console.log("Logging out");
  LocalStorage.clear();
  window.location = "/";
};

Vue.prototype.$logout = logout;

export { logout };
