import Vue from "vue";
export const EventBus = new Vue();
import { LocalStorage } from "quasar";
//  Back end API URLS
export const localApiURL = "api.mycanapy.com.ddev.site";
export const stageApiURL = "api.mycanapy.com";
export const prodApiURL = "api.mycanapy.com";

//  Front end domains
export const localDomain = "localhost";
export const stageDomain = "stage-app.mycanapy.com";
export const prodDomain = "app.mycanapy.com";

/**
 * Consider the hostname of the brower's location and determine which api host the app should use for backend endpoints
 * @return {string} Hostname of the backend api
 */
function getBackendUrl() {
  let assingedUrl = localApiURL;
  // console.log("Front End " + window.location.hostname);
  if (typeof window !== "undefined") {
    switch (window.location.hostname) {
      case localDomain:
        // console.log("Local");
        assingedUrl = localApiURL;
        break;
      case stageDomain:
        // console.log("Stage");
        assingedUrl = stageApiURL;
        break;
      case prodDomain:
        // console.log("Production");
        assingedUrl = prodApiURL;
        break;
      default:
        // console.log("Default");
        assingedUrl = localApiURL;
    }
  }

  // console.log("Back End " + assingedUrl);
  return assingedUrl;
}

export const baseUrl = "https://" + getBackendUrl();
export const jsonApiBaseUrl = "https://" + getBackendUrl() + "/jsonapi";
export const rtBaseUrl = "https://" + getBackendUrl() + "/rtapi/v1/entity";

export const navLinks = [
  { label: "About Us", url: "/about" },
  { label: "Find a Campaign", url: "/campaigns" },
  { label: "Start a Campaign", url: "/start" },
  { label: "Login", url: "/login" },
  { label: "Sign Up", url: "/signup" }
];

export const footerNavLinks = [
  { label: "Our Fees + Pricing", url: "/about" },
  { label: "Why MSG", url: "/campaigns" },
  { label: "About Us", url: "/signup" },
  { label: "Why Big Tech Sux", url: "/login" },
  { label: "Our User Agreement", url: "/signup" }
];

export const footerNavLinks2 = [
  { label: "Fundraiser", url: "/about" },
  { label: "Fundraiser 2", url: "/campaigns" },
  { label: "Fundraiser 3", url: "/signup" },
  { label: "Fundraiser 4", url: "/login" }
];

export const footerNavLinks3 = [
  { label: "Join as a Contributor", url: "/about" },
  { label: "Add a Project", url: "/campaigns" },
  { label: "Give Directly to MSG", url: "/signup" }
];

export const userPayload = {
  username: "",
  token: ""
};

export const getToken = () => {
  // console.log('User Payload Token', userPayload.token)
  if (userPayload.token === "" || userPayload.token === undefined) {
    // console.log(LocalStorage.getAll())
    userPayload.token = LocalStorage.getItem("access_token");
  }
  return userPayload.token;
};
export const getUuid = () => {
  // console.log('User Payload Uuid', userPayload.uuid)
  if (userPayload.uuid === "" || userPayload.uuid === undefined) {
    // console.log('UUID', LocalStorage.getAll())
    userPayload.uuid = LocalStorage.getItem("uuid");
  }
  return userPayload.uuid;
};

export const facebookURL = "https://facebook.com/";

export const states = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" }
];

// export const prettyErrors = 'Pretty'
export const prettyErrors = errorMsg => {
  // console.log('In Constants', errorMsg)
  if (errorMsg.includes("is already taken")) {
    return "That email is already in use";
  } else {
    return errorMsg;
  }
};
