
const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Index.vue') },
      { path: 'about', component: () => import('pages/about/About.vue') },
      { path: 'campaigns', component: () => import('pages/campaigns/CampaignIndex.vue') },
      { path: 'campaigns/:id', component: () => import('pages/campaigns/CampaignIndividual.vue') },
      { path: 'press', component: () => import('pages/about/Press.vue') },
      { path: "login", component: () => import("pages/user/Login.vue") },
      { path: "signup", component: () => import("pages/user/Signup.vue") },
      { path: "start", component: () => import("pages/user/Start.vue") },
      { path: 'privacy', component: () => import('pages/Privacy.vue') },
      { path: 'Terms', component: () => import('pages/Terms.vue') },
      {
        path: "/user",
        component: () => import("layouts/UserLayout.vue"),
        children: [
          { path: "", component: () => import("pages/user/Index.vue") },
          {
            path: "settings",
            component: () => import("pages/user/Settings.vue")
          }
        ],
        meta: { authRequired: true }
      },
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
